(function($){

    var continents = $('.continents');

    if (continents.length) {
        var $container = continents.isotope({
            itemSelector: '.grid-item',
            layoutMode: 'fitRows'
        });

        $('.filter button').on('click', function(){
            var filterValue = $(this).attr('data-filter');
            $container.isotope( { filter: filterValue } );
        });
    }

})(jQuery);
