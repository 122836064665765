(function($){
    var mainMenu            = $('.main-menu'),
        menuLeft            = $('header .left'),
        menuBottomSection   = $('header .left .bottom'),
        menuRight           = $('header .right'),
        header              = $('header');

    mainMenu.on('click', function(){
        mainMenu.add(menuLeft).add(menuRight).toggleClass('open');
        header.toggleClass('opacity');
        menuBottomSection.animate({
           opacity: 1
        });
    });
})(jQuery);