(function($){
    if( $(window).scrollTop() > 90 ){
        $('header').toggleClass('smaller');
    }

    $(window).on('scroll', function(){
        var scrollPos = $(this).scrollTop();
        if(  scrollPos > 90 ){
            $('header').addClass('smaller');
        }else{
            $('header').removeClass('smaller');
        }
    });

    $('.wp-caption').removeAttr('style');


    // Homepage Days travelling counter
    var startDate = moment([2016, 10, 19]),
        todayDate = moment([2017, 7, 19]),
        travelling = todayDate.diff(startDate, 'days'),
        //travelling = Math.abs(startDate.diff(todayDate, 'days')),
        daysTravellingDiv = $('#stats .days_travelling');

    daysTravellingDiv.html(travelling);

    // Match div heights
    $('.match_height').matchHeight();

})(jQuery);